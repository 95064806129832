import {apiGetBackgroundImg} from '@/api/user/login'

import WellCache from "well-cache";

let well_cache = new WellCache({prefix: localStorage.getItem('user_uniq_id'), mode: 'IDB'})
let cache_time = 3600 * 4 * 1000;//4小时
let cache_key = 'background-img';


export async function getBackgroundImg() {
    const bgImg = await well_cache.getSync(cache_key);

    if (bgImg.data != null) {
        if (parseInt(bgImg.data.time) + cache_time < parseInt(new Date().getTime())) {
            resetBackgroundImg();
        }
        return JSON.parse(bgImg.data.results);
    } else {
        return resetBackgroundImg();
    }
}

export async function resetBackgroundImg() {
    let res = await apiGetBackgroundImg();

    if (res.data.err_no == 0) {
        let data = {};
        data.results = JSON.stringify(res.data.results);
        data.time = new Date().getTime();
        well_cache.save(cache_key, data);
    }

    return res.data.results;
}
